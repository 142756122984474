
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function BranchDetailStructuredData({ branch, pageurl }) { 
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  const site_url = process.env.GATSBY_SITE_URL;

  var contacts = [];
  if (branch.sales_dept) {
    contacts.push({
      "@type": "ContactPoint",
      "telephone": branch.sales_dept?.phone,
      "contactType": "customer service",
      "name": "Residential Sales"
    })
  }
  if (branch.lettings_dept) {
    contacts.push({
      "@type": "ContactPoint",
      "telephone": branch.lettings_dept?.phone,
      "contactType": "customer service",
      "name": "Residential Lettings"
    })
  }
  var address = encodeURIComponent(`${branch.address?.street} ${branch.address?.town} ${branch.address?.county} ${branch.address?.postcode}`) 
 
  var ldJson =
  {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": site_url + '/branchfinder/estate-agents-in-' + branch.slug
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": branch.address?.county,
      "addressRegion": branch.address?.town,
      "postalCode": branch.address?.postcode,
      "streetAddress": branch.address?.street
    },
    "name": `Wards of Kent ${branch.branch_name}`,
    "openingHours": ["Mo-Fr 09:00-18:00","Sa 09:00-17:00"],
    "url": site_url + '/branchfinder/estate-agents-in-' + branch.slug,
    "contactPoint": contacts,
    "telephone": branch.sales_dept?.phone,
    "branchOf": {
      "@type": "Corporation",
      "name": "Wards of Kent",
      "logo": {
        "@type": "ImageObject",
        "url": cloud_url + '/images/mail-logo.png',
        "width": 243,
        "height": 34
      }
    },
    "image": branch.Banner_Image?.url,
    "hasMap": `https://www.google.co.uk/maps/dir/` + address
  }
    ;
  return (
    <Helmet>
      <script defer type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default BranchDetailStructuredData
